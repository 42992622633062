/**
 * 下载文件统一处理
 * @type {string[]}
 */

const contentType = [
  'application/x-msdownload',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/octet-stream',
]

// @ts-ignore
const download = res => {
  const content = res.data
  const contentDisposition = res.headers['content-disposition']
  const fileNameIndex = contentDisposition.indexOf('filename')
  const blob = new Blob([content])
  const fileName =
    decodeURIComponent(
      contentDisposition.substring(fileNameIndex).split('=')[1],
    ) || ''

  if ('download' in document.createElement('a')) {
    // 非IE下载
    const eLink = document.createElement('a')
    eLink.download = fileName
    eLink.style.display = 'none'
    eLink.href = URL.createObjectURL(blob)
    document.body.appendChild(eLink)
    eLink.click()
    URL.revokeObjectURL(eLink.href) // 释放URL 对象
    document.body.removeChild(eLink)
  } else {
    // IE10+下载
    // @ts-ignore
    navigator.msSaveBlob(blob, fileName)
  }
}
// @ts-ignore
function downloadDocument(response) {
  const { headers } = response

  if (headers && contentType.includes(headers['content-type'])) {
    download(response)
    return false
  } else {
    return true
  }
}

export default downloadDocument

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { registerMicroApps } from 'qiankun'
import microApps from './micro-app'
import './plugins/ant-design-vue.js'
import './assets/index.less'
import service from '@/utils/request'
import storage from '@/utils/storage'

Vue.prototype.$http = service
Vue.prototype.$storage = storage

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

registerMicroApps(microApps, {
  // beforeLoad: () => {
  //   // console.log('before load app.name====>>>>>', app.name)
  // },
  beforeMount: [
    app => {
      console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
    },
  ],
  // afterMount: [
  //   app => {
  //     console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
  //   },
  // ],
  // afterUnmount: [
  //   app => {
  //     console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
  //   },
  // ],
})
// setDefaultMountApp('/login')
// start()

<template>
  <div id="layout-wrapper">
    <router-view />
  </div>
</template>

<script>
import actions from '@/shared/actions'
import storage from '@/utils/storage'

export default {
  mounted() {
    const userInfo = storage.getItem('userInfo')
    if (userInfo) {
      actions.setGlobalState(userInfo)
    }
  },
}
</script>

<style lang="less" scoped>
#layout-wrapper {
  min-width: 1200px;
  height: 100vh;
  overflow-x: auto;
}
</style>

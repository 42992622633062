import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
  },
  {
    path: '/new-login',
    name: 'newLogin',
    component: () => import('@/views/new-login'),
  },
  {
    path: '/*',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard'),
      },
      {
        path: '/todo',
        name: 'todo',
        component: () => import('@/views/todo/todo'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    next('/login')
  } else {
    next()
  }
})

export default router

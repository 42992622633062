import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userInfo: storage.getItem('userInfo') || {},
  },
  getters: {},
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
  },
  actions: {},
  modules: {},
})

store.subscribe(({ type, payload }) => {
  if (type === 'setUserInfo') {
    storage.setItem('userInfo', payload)
  }
})

export default store

const microApps = [
  {
    // 系统管理模块（React）
    name: 'system-management',
    entry: process.env.VUE_APP_SYSTEM_MANAGEMENT,
    activeRule: '/system-management',
  },
  {
    // 贸易模块（Vue）
    name: 'trading',
    entry: process.env.VUE_APP_TRADING,
    activeRule: '/trading',
  },
  {
    // 跨境模块（Vue）
    name: 'cross-border',
    entry: process.env.VUE_APP_CROSS_BORDER,
    activeRule: '/cross-border',
  },
  {
    // 运营模块（Vue）
    name: 'operation-platform',
    entry: process.env.VUE_APP_OPERATION_PLATFORM,
    activeRule: '/operation-platform',
  },
  {
    // 资金模块（Vue）
    name: 'fund',
    entry: process.env.VUE_APP_FUND,
    activeRule: '/fund',
  },
  {
    // 综合管理模块（Vue）
    name: 'integrated',
    entry: process.env.VUE_APP_INTEGRATED,
    activeRule: '/integrated',
  },
  {
    // 财务模块（Vue）
    name: 'finance',
    entry: process.env.VUE_APP_FINANCE,
    activeRule: '/finance',
  },
  // 账户模块（Vue）
  {
    name: 'middle-account',
    entry: process.env.VUE_APP_MIDDLE_ACCOUNT,
    activeRule: '/middle-account',
  },
]

const apps = microApps.map(item => {
  return {
    ...item,
    container: '#child-viewport', // 子应用挂载的div
    props: {
      routerBase: item.activeRule, // 下发基础路由
      // getGlobalState: store.getGlobalState, // 下发getGlobalState方法
    },
  }
})

export default apps

/**
 * 环境配置封装
 */
const env = process.env.NODE_ENV || 'production'

const EnvConfig = {
  development: {
    baseApi: '/api/core-api',
    mockApi: '',
  },
  test: {
    baseApi: '',
    mockApi: '',
  },
  production: {
    baseApi: '/core-api',
    mockApi: '',
  },
}

const resultConfig = {
  env,
  mock: false,
  namespace: 'main',
  ...EnvConfig[env],
}
export default resultConfig
